<template>
  <input
    v-model="modelValue.searchString"
    id="searchString"
    type="text"
    class="flex-1 min-w-0 block w-full px-3 py-2 rounded-md border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
    placeholder="Type to start searching..."
    @change="emitChange"
  />

  <DropdownFilter title="More...">
    <h6 class="mb-3 text-sm font-medium text-gray-900 dark:text-white">
      Category
    </h6>
    <ul class="space-y-2 text-sm" aria-labelledby="dropdownDefault">
      <li class="flex items-center" v-for="option of options">
        <input
          :id="option.key"
          type="checkbox"
          :checked="option.value"
          class="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
          @change="emitChange"
        />

        <label
          :for="option.key"
          class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100"
        >
          {{ option.label }}
        </label>
      </li>
    </ul>
  </DropdownFilter>
</template>

<script setup>
import DropdownFilter from '@/components/DropdownFilter.vue';

const props = defineProps(['modelValue']);
const emit = defineEmits(['update:modelValue']);

const options = [
  { label: 'Show Create', key: 'showCreate', value: true },
  { label: 'Show Edit', key: 'showEdit', value: true },
  { label: 'Show Delete', key: 'showDelete', value: true },
  { label: 'Show Read', key: 'showRead', value: true },
  { label: 'Show View All', key: 'showViewAll', value: true },
  { label: 'Show Modify All', key: 'showModifyAll', value: true },
];

const emitChange = (e) => {
  const newModelValue = {
    ...props.modelValue,
    [e.target.id]: e.target.id === 'searchString' ? e.target.value : e.target.checked,
  };
  emit('update:modelValue', newModelValue);
};
</script>
