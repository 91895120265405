import type Auth from "~/types/auth";
import type Profile from "~/types/profile";

export const useSalesforce = () => {
  const isAuthenticated = ref(false);
  const error = ref('');
  const auth = useCookie<{
    access_token: string,
    signature: string,
    scope: string,
    instance_url: string,
    id: string,
    token_type: string,
    issued_at: string,
    api_instance_url: string
  }>('auth');

  const authCode = useCookie('authCode');

  const config = useRuntimeConfig()

  const login = async () => {
    try {
      await navigateTo(
        `https://login.salesforce.com/services/oauth2/authorize?client_id=${config.public.clientId}&redirect_uri=${config.public.redirectUrl}&response_type=code`,
        { external: true }
      );
    } catch (e) {
      error.value = (e as any)?.message
      throw e
    }
  }

  const logout = () => {
    const authCookie = useCookie('authCode')
    const authCode = useCookie('isAuthenticated')
    authCookie.value = undefined
    authCode.value = undefined
  }

  const authorize = async () => {
    try {
      await $fetch(`/api/auth?code=${authCode.value}`);
    } catch (e) {
      error.value = (e as any)?.message
      throw e
    }
  }

  const query: any = async (q: string) => {
    try {
      return await $fetch(`/api/query?q=${q}`);
    } catch (e) {
      throw e;
    }
  }


  const fetchPermissionSets = async () => {
    try {
      const response = await query(`SELECT Id, Name, ProfileId, Profile.Name FROM PermissionSet`);

      if (!response.done) {
        throw new Error(`Failed to fetch permission sets: ${error.value}`)
      }

      response.records?.forEach((record: Profile) => (record.Name = record.ProfileId ? record.Profile.Name : record.Name));
      return response.records;
    } catch (e) {
      if (e.response.status === 401) {
        handle401();
      }
    }
  }

  const fetchProfiles = async () => {
    try {
      const response = await query(`SELECT Id, Name FROM Profile`);
      return response.records;
    } catch (e) {
      throw new Error(`Failed to fetch profile: ${(e as any)}`)
    }
  }

  const fetchFieldPermissions = async (profileIds: string[]) => {
    try {
      const response = await query(`SELECT Id, ParentId, Parent.Name, Parent.ProfileId, Parent.Profile.Name, SobjectType, Field, PermissionsEdit, PermissionsRead, SystemModstamp
        FROM FieldPermissions WHERE ParentId IN ('${profileIds.join('\',\'')}') ORDER BY ParentId, Field ASC`);
      return response;
    } catch (e) {
      if (e.response.status === 401) {
        handle401();
      }
    }
  }

  const fetchObjectPermissions = async (profileIds: string[]) => {
    try {
      const response = await query(`SELECT Id, ParentId, Parent.Name, Parent.ProfileId, Parent.Profile.Name, SobjectType, PermissionsCreate, PermissionsRead, PermissionsEdit, PermissionsDelete, PermissionsViewAllRecords, PermissionsModifyAllRecords, SystemModstamp
        FROM ObjectPermissions WHERE ParentId IN ('${profileIds.join('\',\'')}') ORDER BY ParentId, SobjectType ASC`
      );
      return response;
    } catch (e) {
      if (e.response.status === 401) {
        handle401();
      }
    }
  }

  const handle401 = async () => {
    console.log('handle401')
    await navigateTo('/logout');
  }

  return {
    login,
    logout,
    authorize,
    fetchProfiles,
    fetchPermissionSets,
    fetchFieldPermissions,
    fetchObjectPermissions,
    isAuthenticated,
    error,
    auth
  }
}