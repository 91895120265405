<template>
  <div class="relative">

    <!-- DROPDOWN BUTTON -->
    <button
      id="dropdownDefault"
      data-dropdown-toggle="dropdown"
      class="min-w-56 flex items-center justify-between w-full px-3 py-2 rounded-md border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      type="button"
      @click="toggleDropdown"
    >
      {{ title }}
      <svg
        class="w-4 h-4 ml-2"
        aria-hidden="true"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M19 9l-7 7-7-7"
        ></path>
      </svg>
    </button>

    <!-- DROPDOWN MENU -->
    <div
      id="dropdown"
      class="absolute top-[40px] z-10 hidden w-56 p-3 bg-white rounded-lg shadow dark:bg-gray-700"
      tabindex="0"
    >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps<{ title: string }>();

const toggleDropdown = () => {
  const dropdown = document.getElementById('dropdown');
  dropdown?.classList.toggle('hidden');
};

</script>

<style scoped>
</style>
