<template>
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
    <div class="space-y-8">
      <div
        class="w-full px-4 py-5 sm:p-6 bg-white shadow overflow-hidden sm:rounded-lg"
      >
        <div class="flex gap-2 mb-3">
          <!-- PROFILE SELECTOR -->
          <select
            v-model="selectedProfileId"
            class="flex-1 min-w-0 block px-2 py-2 rounded-md border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-white"
            name="profilesList"
            id="profilesList"
            :class="{'text-gray-400': selectedProfileId == ''}"
            @change="addProfileToCompare"
          >
            <option value="">Select a profile</option>
            <option
            class="text-black"
              v-for="profile in permissionSetsOptions"
              :value="profile.value"
            >
              {{ profile.label }}
            </option>
          </select>

          <!-- CLEAR BUTTON -->
          <button
            v-if="selectedProfiles.length > 0"
            type="button"
            title="Clear"
            :disabled="loading"
            class="text-white bg-red-400 disabled:bg-gray-300 hover:bg-red-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm p-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            @click="clearSelectedProfiles"
            >
            <img width="18" src="/public/icons/clear.svg"></img>
            <span class="ml-2">Clear</span>
          </button>

          <!-- COMPARE BUTTON -->
          <button
            type="button"
            title="Compare"
            :disabled="loading || !selectedProfiles.length"
            class="text-white bg-blue-600 disabled:bg-gray-300 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm p-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            @click="analyze"
            >
            <img width="18" src="/public/icons/fallback.svg"></img>
            <span class="ml-2">Compare</span>
          </button>
        </div>

        <!-- SELECTED PROFILES BADGES -->
        <DismissibleBadge
          class="mb-2"
          v-for="profile in selectedProfiles"
          :label="profile.Profile ? profile.Profile.Name : profile.Name"
          :value="profile.Id"
          @remove="removeProfileToCompare"
        ></DismissibleBadge>
      </div>

      <!-- RESULTS -->
      <div class="bg-white shadow sm:rounded-lg">
        <!-- <div class="px-4 py-5 sm:px-6">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            {{ fieldPermissions?.Name }}
          </h3>
        </div> -->

        <!-- NO DATA  -->
         <div v-if="noDataToShow" class="px-4 py-5 sm:p-6 text-center text-lg text-gray-600">
          Select a permission and click <b>Compare</b>
         </div>
<template v-else>
        <!-- TABS -->
        <div  class="border-b border-gray-200">
          <nav class="-mb-px flex" aria-label="Tabs">
            <button
              v-for="tab in tabs"
              :key="tab.name"
              @click="currentTab = tab.name"
              :class="[
                currentTab === tab.name
                  ? 'border-indigo-500 text-indigo-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                'w-1/2 py-4 px-1 text-center border-b-2 font-medium text-sm',
              ]"
            >
              {{ tab.label }}
            </button>
          </nav>
        </div>
        

        <!-- TAB CONTENT -->
        <div class="px-4 py-5 sm:p-6">
          <div :class="{ hidden: currentTab !== 'fields' }">
            <FieldPermissionList
            :permissions="fieldPermissions"
            :selected-profiles="_selectedProfiles"
            :loading="loading"
            />
          </div>
          <div :class="{ hidden: currentTab !== 'objects' }">
            <ObjectPermissionList
                :permissions="objectPermissions"
                :selected-profiles="_selectedProfiles"
                :loading="loading"
              />
          </div>
        </div>
      </template>
          <!-- <UserPermissionList
            v-if="currentTab === 'user'"
            :permissions="permissionData?.records"
          /> -->
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import type Profile from '@/types/profile';
import FieldPermissionList from './permission/FieldPermissionList.vue';
import ObjectPermissionList from './permission/ObjectPermissionList.vue';

const { fetchProfiles, fetchPermissionSets } = useSalesforce();
const { mergeFieldPermissions, mergeObjectPermissions } = useCompare();

// const profiles = (await fetchProfiles()) as Array<Profile>;
const permissionSets = (await fetchPermissionSets()) as Profile[];

const selectedProfileId: Ref<string> = ref('');
const selectedProfiles: Ref<Array<Profile>> = ref([]);
const _selectedProfiles: Ref<Array<Profile>> = ref([]);
const mergedProfiles = ref({});

const searchId = ref('');
const loading = ref(false);
const fieldPermissions: Ref<any> = ref([]);
const objectPermissions: Ref<any> = ref([]);
const currentTab = ref('objects');

const tabs = [
  { name: 'objects', label: 'Object Permissions' },
  { name: 'fields', label: 'Field Permissions' },
  // { name: 'user', label: 'User Permissions' },
];

const permissionSetsOptions = computed(() => {
  return permissionSets?.map((p) => ({
    label: !p.Profile
      ? `Permission set: ${p.Name}`
      : `Profile: ${p.Profile.Name}`,
    value: p.Id,
  }));
});

const noDataToShow = computed(() => {
  return !fieldPermissions.value.length && !objectPermissions.value.length;
});

const analyze = async () => {
  if (!selectedProfiles.value.length) return;

  _selectedProfiles.value = [...selectedProfiles.value];

  loading.value = true;
  try {
    try {
      const profileIds = selectedProfiles.value?.map((p) => p.Id);
      fieldPermissions.value = await mergeFieldPermissions(profileIds);
      objectPermissions.value = await mergeObjectPermissions(profileIds);
    } catch {
      // permissionData.value = await fetchPermissionSet(searchId.value);
    }
  } catch (e) {
    console.error(e);
  } finally {
    loading.value = false;
  }
};

const addProfileToCompare = () => {
  const alreadyAdded = selectedProfiles.value.find(
    (profile) => profile.Id === selectedProfileId.value
  );
  if (alreadyAdded || !selectedProfileId.value) return;

  selectedProfiles.value.push(
    permissionSets?.find((p) => p.Id === selectedProfileId.value)!
  );

  // analyze();
};

const removeProfileToCompare = (id: string) => {
  selectedProfiles.value = selectedProfiles.value.filter((p) => p.Id !== id);
};

const clearSelectedProfiles = () => {
  selectedProfiles.value = [];
  fieldPermissions.value = [];
}
</script>
