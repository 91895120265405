import type FieldPermission from "~/types/fieldPermission";
import type MergedPermission from "~/types/mergedPermission";
import type ObjectPermission from "~/types/objectPermission";

export const useCompare = () => {

    const mergeFieldPermissions = async (profileIds: string[]) => {
        const fieldPermission = await useSalesforce().fetchFieldPermissions(profileIds);

        const uniqueFields = [...new Set(fieldPermission.records.map((fp: FieldPermission) => fp.Field))] as string[];
        uniqueFields.sort();

        const groupedPermissions: { [key: string]: Array<FieldPermission> } = {}
        for (let fp of fieldPermission.records) {
            const existingFieldPermissions = groupedPermissions[fp.Field as string];
            if (!existingFieldPermissions) {
                groupedPermissions[fp.Field] = [fp];
            } else {
                existingFieldPermissions.push(fp);
            }
        }

        const mergedFieldPermission: MergedPermission[] = [];
        for (let fieldName of uniqueFields) {
            const objectName = fieldName.split('.').shift() as string;
            const fieldPermissions = groupedPermissions[fieldName];

            const missingProfileIds = profileIds.filter(profileId => !fieldPermissions.map(p => p.ParentId).includes(profileId));
            if (missingProfileIds && missingProfileIds.length > 0) {
                for (let missingProfileId of missingProfileIds) {
                    fieldPermissions.push({
                        ParentId: missingProfileId,
                        SobjectType: objectName,
                        Field: fieldName,
                        PermissionsEdit: false,
                        PermissionsRead: false,
                    } as FieldPermission);
                }
            }

            fieldPermissions.sort((a, b) => profileIds.indexOf(a.ParentId) - profileIds.indexOf(b.ParentId));

            mergedFieldPermission.push({
                objectName,
                fieldName,
                permissions: fieldPermissions,
            } as MergedPermission);
        }

        return mergedFieldPermission;
    }

    const mergeObjectPermissions = async (profileIds: string[]) => {
        const permissions = await useSalesforce().fetchObjectPermissions(profileIds);

        const uniqueObjects = [...new Set(permissions.records.map((fp: ObjectPermission) => fp.SobjectType))] as string[];
        uniqueObjects.sort();

        const groupedPermissions: { [key: string]: Array<ObjectPermission> } = {}
        for (let fp of permissions.records) {
            const existingPermissions = groupedPermissions[fp.SobjectType as string];
            if (!existingPermissions) {
                groupedPermissions[fp.SobjectType] = [fp];
            } else {
                existingPermissions.push(fp);
            }
        }

        const mergedFieldPermission: MergedPermission[] = [];
        for (let objectName of uniqueObjects) {
            const fieldPermissions = groupedPermissions[objectName];

            const missingProfileIds = profileIds.filter(profileId => !fieldPermissions.map(p => p.ParentId).includes(profileId));
            if (missingProfileIds && missingProfileIds.length > 0) {
                for (let missingProfileId of missingProfileIds) {
                    fieldPermissions.push({
                        ParentId: missingProfileId,
                        SobjectType: objectName,
                        PermissionsCreate: false,
                        PermissionsRead: false,
                        PermissionsEdit: false,
                        PermissionsDelete: false,
                        PermissionsViewAllRecords: false,
                        PermissionsModifyAllRecords: false,
                    } as ObjectPermission);
                }
            }

            fieldPermissions.sort((a, b) => profileIds.indexOf(a.ParentId) - profileIds.indexOf(b.ParentId));

            mergedFieldPermission.push({
                objectName,
                permissions: fieldPermissions,
            } as MergedPermission);
        }

        return mergedFieldPermission;
    }

    return {
        mergeFieldPermissions,
        mergeObjectPermissions
    }
}