export default defineNuxtRouteMiddleware((to, from) => {
  const authCode = useCookie('authCode');
  const isAuthenticated = useCookie('isAuthenticated') as Ref<boolean>;

  if (to.path === '/logout') {
    isAuthenticated.value = false;
    authCode.value = null;
  }

  if (to.path === '/oauth/callback') {
    authCode.value = to.query.code as string;
    return useSalesforce().authorize().then(resp => {
      isAuthenticated.value = true;
      return navigateTo('/', { replace: false })
    }).catch((err: any) => {
      return navigateTo('/logout', { replace: false })
    })
  }

  if (to.path !== '/') {
    return navigateTo('/', { replace: false })
  }
})