<template>
  <div  class="light min-h-screen bg-gray-50 ">
    <div v-if="isAuthenticated">
      <nav class="bg-white shadow">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="flex justify-between h-16">
            <div class="flex">
              <div class="flex-shrink-0 flex items-center">
                <h1 class="text-xl font-bold text-gray-900">SF Profile Analyzer</h1>
              </div>
            </div>
            <div class="flex items-center">
              <button
                @click="handleLogout"
                class="ml-3 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </nav>
      <PermissionAnalyzer />
    </div>
    <LoginForm v-else/>
  </div>
</template>

<script setup>
const { logout } = useSalesforce()

const isAuthenticated = useCookie('isAuthenticated')

const handleLogout = () => {
  logout()
}
</script>