<template>
  <!-- FILTER -->
  <div class="mb-4 flex gap-3">
    <ObjectPermissionFilter v-model="filters"></ObjectPermissionFilter>

    <!--
    <select
      v-model="selectedObjectName"
      class="flex-1 min-w-0 block w-full px-3 py-2 rounded-md border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-white"
      :class="{ 'text-gray-400': selectedObjectName == '' }"
      name="objectName"
      id="objectName"
    >
      <option value="">Select an object</option>
      <option v-for="object in objects" :value="object.Name" class="text-black">
        {{ object.Name }}
      </option>
    </select>

    <div class="flex items-center">
      <input
        v-model="showRead"
        type="checkbox"
        class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
        id="hs-default-checkbox"
      />
      <label
        for="hs-default-checkbox"
        class="text-sm text-gray-500 ms-3 dark:text-neutral-400"
        >Show Read</label
      >
    </div>

    <div class="flex items-center">
      <input
        v-model="showEdit"
        type="checkbox"
        class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
        id="hs-default-checkbox"
      />
      <label
        for="hs-default-checkbox"
        class="text-sm text-gray-500 ms-3 dark:text-neutral-400"
        >Show Edit</label
      >
    </div> -->
  </div>

  <!-- TABLE -->
  <div
    class="relative overflow-hidden overflow-x-auto overflow-y-auto max-h-[800px] [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500"
  >
    <table
      v-if="filteredPermissions.length"
      class="w-full divide-y divide-gray-200 dark:divide-neutral-700 relative"
    >
      <thead>
        <!-- FIRST HEADER ROW -->
        <tr class="sticky first-row shadow-md">
          <th
            class="sticky first-col text-start px-6 py-3 text-xs font-medium text-gray-500 uppercase dark:text-neutral-500 border-r"
          >
            Object Name
          </th>
          <th
            v-for="profile in selectedProfiles"
            :key="profile.Id"
            :colspan="columnsToSpan"
            class="text-center px-6 py-3 text-xs font-medium text-gray-500 uppercase dark:text-neutral-500 border-x"
          >
            {{ profile.Name }}
          </th>
        </tr>
      </thead>
      <tbody class="">
        <!-- LOADING -->
        <tr v-if="loading" class="text-center">
          <td
            :colspan="selectedProfiles.length * columnsToSpan + 1"
            class="py-4 text-center"
          >
            <div
              class="inline-flex animate-spin size-8 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"
              role="status"
              aria-label="loading"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </td>
        </tr>

        <!-- DATA -->
        <tr
          v-else
          v-for="perm in filteredPermissions"
          :key="perm.objectName"
          class="divide-y"
        >
          <td
            class="sticky first-col font-bold	 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-neutral-200 border-r border-t"
          >
            {{ perm.objectName }}
          </td>

          <template v-for="permission in perm.permissions" :key="permission">
            <!-- CREATE -->
            <td
              v-if="filters.showCreate"
              class="text-center px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200 border-l"
            >
              <span
                :class="permission.PermissionsCreate ? greenClass : redClass"
              >
                CREATE
              </span>
            </td>

            <!-- READ -->
            <td
              v-if="filters.showRead"
              class="text-center px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200 border-l"
            >
              <span :class="permission.PermissionsRead ? greenClass : redClass">
                READ
              </span>
            </td>

            <!-- EDIT -->
            <td
              v-if="filters.showEdit"
              class="text-center px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200 border-l"
            >
              <span :class="permission.PermissionsEdit ? greenClass : redClass">
                EDIT
              </span>
            </td>

            <!-- DELETE -->
            <td
              v-if="filters.showDelete"
              class="text-center px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200 border-l"
            >
              <span
                :class="permission.PermissionsDelete ? greenClass : redClass"
              >
                DELETE
              </span>
            </td>

            <!-- VIEW ALL -->
            <td
              v-if="filters.showViewAll"
              class="text-center px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200 border-l"
            >
              <span
                :class="
                  permission.PermissionsViewAllRecords ? greenClass : redClass
                "
              >
                VIEW ALL
              </span>
            </td>

            <!-- MODIFY ALL -->
            <td
              v-if="filters.showModifyAll"
              class="text-center px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200 border-l"
            >
              <span
                :class="
                  permission.PermissionsModifyAllRecords ? greenClass : redClass
                "
              >
                MODIFY ALL
              </span>
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import type MergedPermission from '~/types/mergedPermission';
import type Profile from '~/types/profile';
import ObjectPermissionFilter from '@/components/filters/ObjectPermissionFilter.vue';

const greenClass =
  'w-min text-center bg-green-50 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-green-600 dark:text-green-200';
const redClass =
  'w-min line-through	 text-center bg-red-50 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-red-600 dark:text-red-200';

const selectedObjectName = ref('');
const searchString = ref('');

const props = defineProps<{
  permissions: MergedPermission[];
  selectedProfiles: Profile[];
  loading: boolean;
}>();

const filters = ref({
  objectName: '',
  searchString: '',
  showCreate: true,
  showRead: true,
  showEdit: true,
  showDelete: true,
  showViewAll: true,
  showModifyAll: true,
});

const objects = computed(() => {
  const uniqueObjects = [
    ...new Set(props.permissions.map((p) => p.objectName)),
  ];
  return uniqueObjects.map((sobject) => ({
    Name: sobject,
  }));
});

const columnsToSpan = computed(() => {
  return [
    filters.value.showCreate,
    filters.value.showRead,
    filters.value.showEdit,
    filters.value.showDelete,
    filters.value.showViewAll,
    filters.value.showModifyAll,
  ].filter((o) => !!o).length;
});

const filteredPermissions = computed(() => {
  if (!filters.value.objectName && !filters.value.searchString) {
    return props.permissions;
  }
  return props.permissions.filter(
    (p) =>
      !filters.value.searchString ||
      p.objectName.toLowerCase().includes(filters.value.searchString.toLowerCase())
  );
});
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
}

.first-col {
  width: 250px;
  min-width: 200px;
  max-width: 400px;
  left: 0px;
}

.first-row {
  top: 0px;
  z-index: 1;
}
</style>
